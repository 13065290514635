import React, { useRef } from "react";
import { Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import Leaders1 from "../components/assets/images/webp/anilBhatia.jpg";
import Leaders2 from "../components/assets/images/webp/Himanshu.jpg";
import Leaders3 from "../components/assets/images/webp/RaghavBhatia.jpg";

const MeetLeaders = () => {
  const slider1 = useRef(null);

  const leaders = [
    { name: "Anil Kumar", designation: "Managing Director", image: Leaders1 },
    {
      name: "Himanshu Chawla",
      designation: "",
      image: Leaders2,
    },
    {
      name: "Raghav Bhatia",
      designation: "",
      image: Leaders3,
    },
  ];

  const settings1 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: "0px",
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      {
        breakpoint: 576,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  return (
    <div className="bg_gray py-5">
      <Container className="py-lg-4">
        <h2
          className="fw-bold fs_8xl mb-lg-5 mb-4 text-center"
          data-aos="fade-down"
        >
          Meet the Leaders
        </h2>
        <Row data-aos="fade-down">
          <Slider
            {...settings1}
            ref={slider1}
            className="d-flex align-items-center"
          >
            {leaders.map((leader, index) => (
              <div
                className="d-flex flex-column justify-content-center pb-3"
                key={index}
              >
                <div
                  className="bg-image-container mx-auto mb-2 shadow rounded-circle cursor_pointer"
                  style={{
                    backgroundImage: `url(${leader.image})`,
                    backgroundSize: "cover",
                    // backgroundPosition: "center center",
                    width: "120px",
                    height: "120px",
                
                    backgroundPosition: " center center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <div className="w-100">
                  <p className="text-black fw-semibold fs_8sm text-center text-nowrap mb-0 pb-1">
                    {leader.name}
                  </p>
                  <p className="fs_6sm fw-light text-center mb-0">
                    {leader.designation}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </Row>
      </Container>
    </div>
  );
};

export default MeetLeaders;
